// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bs_k5";
export var caseStudyBackground__lineColor = "bs_kX";
export var caseStudyHead__imageWrapper = "bs_kV";
export var caseStudyProjectsSection = "bs_k6";
export var caseStudyQuote__bgLight = "bs_k3";
export var caseStudyQuote__bgRing = "bs_k2";
export var caseStudyQuote__bgRingDark = "bs_lJ";
export var caseStudyQuote__bottomVideo = "bs_lK";
export var caseStudySolution__ring = "bs_k0";
export var caseStudySolution__ringTwo = "bs_k1";
export var caseStudyVideo = "bs_lg";
export var caseStudyVideo__ring = "bs_k8";
export var caseStudy__bgDark = "bs_kT";
export var caseStudy__bgLight = "bs_kS";